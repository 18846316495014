import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import { HSRCharacter } from '../../../modules/hsr/common/components/hsr-character';

const HSRGuidesPathPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Paths</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_paths.png"
            alt="Paths"
          />
        </div>
        <div className="page-details">
          <h1>Honkai: Star Rail Paths</h1>
          <h2>The Paths system in Honkai: Star Rail explained.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Elements" />
        <p>
          Each Character in Honkai: Star Rail has a <strong>Path</strong>. You
          can think of Paths as character classes - a character’s Path will give
          you a general idea of what role that Character fulfills in combat as
          well as what Light Cones they can fully utilize.
        </p>
        <p>
          There are currently 14 known Paths in the game, but only 7 are
          playable:
        </p>
        <ul>
          <li>
            <strong>Abundance</strong> (Healer)
          </li>
          <li>
            <strong>Destruction</strong> (General DPS)
          </li>
          <li>
            <strong>Erudition</strong> (Multi-Target DPS)
          </li>
          <li>
            <strong>Harmony</strong> (Offensive/Utility Support)
          </li>
          <li>
            <strong>Hunt</strong> (Single Target DPS)
          </li>
          <li>
            <strong>Nihility</strong> (Debuffer)
          </li>
          <li>
            <strong>Preservation</strong> (Defensive Support/Tank)
          </li>
        </ul>
        <p>
          And here's the list of Paths that currently doesn't have any
          characters attached to it:
        </p>
        <ul>
          <li>Beauty</li>
          <li>Elation</li>
          <li>Enigmata</li>
          <li>Propagator</li>
          <li>Rememberance</li>
          <li>Trailblaze</li>
          <li>Voracity</li>
        </ul>
        <SectionHeader title="Paths and characters" />
        <p>
          Below you will find a list of all characters available in the game
          grouped by their Path:
        </p>
        <h5>Abundance characters</h5>
        <p>
          Characters on the Abundance Path are the Healers in Honkai: Star Rail.
          They focus on restoring allies' HP and keeping them alive.
        </p>
        <div className="employee-container for-nikke">
          <HSRCharacter mode="icon" slug="bailu" enablePopover />
          <HSRCharacter mode="icon" slug="gallagher" enablePopover />
          <HSRCharacter mode="icon" slug="huohuo" enablePopover />
          <HSRCharacter mode="icon" slug="lingsha" enablePopover />
          <HSRCharacter mode="icon" slug="luocha" enablePopover />
          <HSRCharacter mode="icon" slug="lynx" enablePopover />
          <HSRCharacter mode="icon" slug="natasha" enablePopover />
        </div>
        <h5>Destruction characters</h5>
        <p>
          Characters on the Destruction path focus on dealing damage - both
          single target and AOE.
        </p>
        <div className="employee-container for-nikke">
          <HSRCharacter mode="icon" slug="arlan" enablePopover />
          <HSRCharacter mode="icon" slug="blade" enablePopover />
          <HSRCharacter mode="icon" slug="clara" enablePopover />
          <HSRCharacter mode="icon" slug="imbibitor-lunae" enablePopover />
          <HSRCharacter mode="icon" slug="firefly" enablePopover />
          <HSRCharacter mode="icon" slug="hook" enablePopover />
          <HSRCharacter mode="icon" slug="jingliu" enablePopover />
          <HSRCharacter mode="icon" slug="misha" enablePopover />
          <HSRCharacter mode="icon" slug="trailblazer-physical" enablePopover />
          <HSRCharacter mode="icon" slug="xueyi" enablePopover />
          <HSRCharacter mode="icon" slug="yunli" enablePopover />
        </div>
        <h5>Erudition characters</h5>
        <p>
          Characters on the Erudition path focus on dealing AOE damage, allowing
          them to quickly deal with whole waves of enemies.
        </p>
        <div className="employee-container for-nikke">
          <HSRCharacter mode="icon" slug="argenti" enablePopover />
          <HSRCharacter mode="icon" slug="herta" enablePopover />
          <HSRCharacter mode="icon" slug="himeko" enablePopover />
          <HSRCharacter mode="icon" slug="jade" enablePopover />
          <HSRCharacter mode="icon" slug="jing-yuan" enablePopover />
          <HSRCharacter mode="icon" slug="qingque" enablePopover />
          <HSRCharacter mode="icon" slug="rappa" enablePopover />
          <HSRCharacter mode="icon" slug="serval" enablePopover />
        </div>
        <h5>Harmony characters</h5>
        <p>
          Characters on the Harmony path focus on supporting their allies with
          various offensive buffs.
        </p>
        <div className="employee-container for-nikke">
          <HSRCharacter mode="icon" slug="asta" enablePopover />
          <HSRCharacter mode="icon" slug="bronya" enablePopover />
          <HSRCharacter mode="icon" slug="hanya" enablePopover />
          <HSRCharacter mode="icon" slug="robin" enablePopover />
          <HSRCharacter mode="icon" slug="ruan-mei" enablePopover />
          <HSRCharacter mode="icon" slug="sparkle" enablePopover />
          <HSRCharacter mode="icon" slug="sunday" enablePopover />
          <HSRCharacter mode="icon" slug="tingyun" enablePopover />
          <HSRCharacter
            mode="icon"
            slug="trailblazer-imaginary"
            enablePopover
          />
          <HSRCharacter mode="icon" slug="yukong" enablePopover />
        </div>
        <h5>Hunt characters</h5>
        <p>
          Characters on the Hunt path focus on dealing single target damage -
          you can consider them as Assassins.
        </p>
        <div className="employee-container for-nikke">
          <HSRCharacter mode="icon" slug="boothill" enablePopover />
          <HSRCharacter mode="icon" slug="dan-heng" enablePopover />
          <HSRCharacter mode="icon" slug="dr-ratio" enablePopover />
          <HSRCharacter mode="icon" slug="feixiao" enablePopover />
          <HSRCharacter
            mode="icon"
            slug="march-7th-swordmaster"
            enablePopover
          />
          <HSRCharacter mode="icon" slug="moze" enablePopover />
          <HSRCharacter mode="icon" slug="seele" enablePopover />
          <HSRCharacter mode="icon" slug="sushang" enablePopover />
          <HSRCharacter mode="icon" slug="topaz" enablePopover />
          <HSRCharacter mode="icon" slug="yanqing" enablePopover />
        </div>
        <h5>Nihility characters</h5>
        <p>
          Characters on the Nihility path focus on weakening enemies with
          debuffs.
        </p>
        <div className="employee-container for-nikke">
          <HSRCharacter mode="icon" slug="acheron" enablePopover />
          <HSRCharacter mode="icon" slug="black-swan" enablePopover />
          <HSRCharacter mode="icon" slug="kafka" enablePopover />
          <HSRCharacter mode="icon" slug="jiaoqiu" enablePopover />
          <HSRCharacter mode="icon" slug="luka" enablePopover />
          <HSRCharacter mode="icon" slug="pela" enablePopover />
          <HSRCharacter mode="icon" slug="sampo" enablePopover />
          <HSRCharacter mode="icon" slug="silver-wolf" enablePopover />
          <HSRCharacter mode="icon" slug="tingyun-fugue" enablePopover />
          <HSRCharacter mode="icon" slug="welt" enablePopover />
        </div>
        <h5>Preservation characters</h5>
        <p>
          Characters on the Preservation path focus on supporting their allies
          (defensively - for example by Shielding them).
        </p>
        <div className="employee-container for-nikke">
          <HSRCharacter mode="icon" slug="aventurine" enablePopover />
          <HSRCharacter mode="icon" slug="fu-xuan" enablePopover />
          <HSRCharacter mode="icon" slug="gepard" enablePopover />
          <HSRCharacter mode="icon" slug="march-7th" enablePopover />
          <HSRCharacter mode="icon" slug="trailblazer-fire" enablePopover />
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      </div>
    </DashboardLayout>
  );
};

export default HSRGuidesPathPage;

export const Head: React.FC = () => (
  <Seo
    title="Paths | Honkai: Star Rail | Prydwen Institute"
    description="The paths system in Honkai: Star Rail explained."
  />
);
